<template>
  <div class="page">
    <!--<span>门票订单详情</span>-->
    <!--顶部订单状态-->
    <div class="head">
      <img class="full-img" mode="widthFix" src="~images/icon/yellowbg.png">
      <span class="title">{{statusMap[status]}}</span>
    </div>
  
    <div class="cont">
      <!--使用日期-->
      <div v-show="status === 0" class="status-box flexcenter status1">
        <span>使用日期：2020年12月30日 星期三</span>
      </div>
    
      <div v-show="status === 1 || status === 2" class="status-box flexcenter status2">
        <img class="img" mode="widthFix" src="~images/hqyc/bxjg.png">
        <div>
          <div>使用日期：</div>
          <div>2020年12月30日 星期三</div>
        </div>
      </div>
    
      <div v-show="status === 3" class="status-box flexcenter status1">
        <span>您购买的门票已取消~</span>
      </div>
    
      <!--门票详细信息-->
      <div class="detail-box">
        <div class="left">
          <img class="full-img" mode="widthFix" src="~images/hqyc/bxjg.png">
        </div>
        <div class="right">
        
          <div class="title-box">
            <div class="title ellipsis2">标题28px常规#191919主标题28px常规#191919主标题28px常规#191919主标题28px中</div>
            <div class="price">￥280</div>
          </div>
        
          <div class="desc-box flexcenter">
            <div>门票类型：成人票</div>
            <div>x2</div>
          </div>
      
        </div>
      </div>
    
      <div class="discount-box flexcenter">
        <div class="left">
          <span class="jian">减</span>
          <span>满500减50</span>
        </div>
        <div class="right">- ￥50</div>
      </div>
    
      <div class="price-box flexcenter">
        <span>订单金额</span>
        <span>￥510</span>
      </div>
    
      <!--游客信息-->
      <div class="customer-box">
        <div class="subtitle">游客信息</div>
      
        <div class="item">
          <!--TODO: 添加已改签、已退票等状态-->
          <div class="title">游客1</div>
          <div>姓名：夏洛</div>
          <div>手机号：13426153081</div>
          <div>身份证号：362329199801020042</div>
          <div class="action-box flexcenter">
            <div></div>
            <div class="right flexcenter">
              <div class="act tui">退票</div>
              <div class="act">改签</div>
            </div>
          </div>
        </div>
      
        <div class="item">
          <div class="title">游客2</div>
          <div>姓名：马冬梅</div>
          <div>手机号：13426153081</div>
          <div>身份证号：362329199801020042</div>
          <div class="action-box flexcenter">
            <div></div>
            <div class="right flexcenter">
              <div class="act tui">退票</div>
              <div class="act">改签</div>
            </div>
          </div>
        </div>
      </div>
    
      <!--订单信息-->
      <div class="order-box">
        <div class="subtitle">订单信息</div>
      
        <div class="item">
          <div>创建时间：2020-12-28 13:34:19</div>
          <div>订单编号：D1234567890</div>
        </div>
    
      </div>
    </div>
  
    <div class="gap"></div>
  
    <!--取消订单-->
    <div class="bottom flexcenter">
      <div></div>
      <div class="cancel">取消订单</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'mpdd',
    data() {
      return {
        // 0 正在出票；1已出票；2已完成；3已取消
        status: 0,
        statusMap: ['正在出票', '已出票', '已完成', '已取消']
      }
    },
    methods: {
      doa() {
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    /*background-color: #F5F5F5;*/
    min-height: 100%;
    font-size: 32px;
    line-height: 1.6;
    font-family: "PingFang SC";
  }

  .head {
    position: relative;
    font-size: 48px;
    font-weight: bold;
    color: #191919;
  }
  .head .title {
    position: absolute;
    left: 36px;
    top: 32px;
  }

  .cont {
    position: relative;
    top: -124px;
    margin: 0 24px;
  }
  .cont .status-box {
    justify-content: space-between;
    margin-bottom: 48px;
    border-radius: 24px;
    box-shadow: 0 4px 14px #878787;
    font-size: 28px;
    color: #191919;
  }
  .cont .status1 {
    justify-content: center;
    height: 124px;
    background-color: #FFFFFF;
  }
  .cont .status2 {
    justify-content: center;
    height: 232px;
    background-color: #FFFFFF;
  }
  .cont .status2 .img {
    margin-right: 30px;
    width: 160px;
  }

  .cont .detail-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .cont .detail-box .left {
    margin-right: 20px;
    width: 140px;
  }
  .cont .detail-box .right {
    flex: 1;
  }
  .cont .detail-box .right .title-box {
    display: flex;
    justify-content: space-between;
    font-size: 28px;
    color: #191919;
  }
  .cont .detail-box .right .title-box .title {
    width: 346px;
  }
  .cont .detail-box .right .desc-box {
    justify-content: space-between;
    font-size: 24px;
    margin: 16px 0;
    color: #777777;
  }
  .cont .discount-box {
    justify-content: space-between;
    margin-bottom: 32px;
    font-size: 24px;
    color: #191919;
  }
  .cont .discount-box .left .jian {
    display: inline-block;
    margin-right: 10px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 4px;
    background-color: #F84F41;
    font-size: 22px;
    color: #FFFFFF;
  }
  .cont .discount-box .right {
    font-weight: 28px;
    color: #F84F41;
  }
  .cont .price-box {
    justify-content: space-between;
    margin-bottom: 36px;
    padding-bottom: 34px;
    font-size: 28px;
    font-weight: bold;
    color: #191919;
    border-bottom: 2px solid #EEEEEE;
  }

  .cont .customer-box {
    margin-bottom: 36px;
    border-bottom: 2px solid #EEEEEE;
  }
  .cont .customer-box .subtitle {
    margin-bottom: 24px;
    font-size: 28px;
    color: #191919;
  }
  .cont .customer-box .item {
    margin-bottom: 32px;
    font-size: 26px;
    color: #777777;
    line-height: 50px;
  }
  .cont .customer-box .item .title {
    font-size: 28px;
    color: #191919;
  }
  .cont .customer-box .item .action-box {
    justify-content: space-between;
  }
  .cont .customer-box .item .action-box .act {
    width: 160px;
    height: 56px;
    line-height: 56px;
    border: 2px solid #999999;
    border-radius: 29px;
    text-align: center;
    font-size: 28px;
    color: #777777;
  }
  .cont .customer-box .item .action-box .tui {
    margin-right: 24px;
  }

  .cont .order-box {
    margin-bottom: 36px;
    font-size: 26px;
    color: #777777;
    line-height: 50px;
  }
  .cont .order-box .subtitle {
    margin-bottom: 24px;
    font-size: 28px;
    color: #191919;
  }

  .gap {
    height: 24px;
    background-color: #F5F5F5;
  }

  .bottom {
    justify-content: space-between;
    height: 110px;
    background-color: #FFFFFF;
  }
  .bottom .cancel {
    margin-right: 24px;
    width: 160px;
    height: 56px;
    line-height: 56px;
    border: 2px solid #999999;
    border-radius: 29px;
    text-align: center;
    font-size: 28px;
    color: #777777;
  }

</style>
